import { Component, OnInit } from '@angular/core';
import { AssetService } from '../asset.service';
import { VersionedAsset } from '@impact/api';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  assets: VersionedAsset[] = [];
  constructor(private assetService: AssetService) { }

  ngOnInit(): void {
    this.getAssets();
  }
  getAssets(): void {
    this.assetService.getAssets().subscribe(assets => this.assets = assets);
  }
}
