<si-landing-page
  [heading]="'APP.NAME' | translate"
  [subtitle]="'APP.CLAIM' | translate"
  [links]="[
    {title: 'Corporate information', href: 'http://www.siemens.com/corporate-information'},
    {title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice'},
    {title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use'}
  ]"
  [availableLanguages]="availableLanguages"
>
  <button type="submit" class="btn btn-primary w-100" routerLink="/main">
    {{ 'APP.LOGIN' | translate }}
  </button>
</si-landing-page>
