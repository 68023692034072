import { Injectable } from '@angular/core';
import { AssetsApi, Configuration, VersionedAsset } from '@impact/api';
import { from, Observable } from 'rxjs';
// import {AssetsApi, VersionedAsset} from "@impact/api/dist/js/api";

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  constructor() { }

  getAssets(): Observable<VersionedAsset[]> {
    const config = new Configuration({
      // eslint-disable-next-line max-len
      apiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpbXBhY3QiLCJpYXQiOjE2NjEzNDc1MzgsImV4cCI6MTc1NjA0MTkzOCwiYXVkIjoiaW1wYWN0Iiwic3ViIjoiaW1wYWN0IiwidXNlcklkIjoiRjNCQ0JCQ0EtMjA2My00RTFGLUE1QjUtQkFGMzM2OEUwMkY5IiwidXNlck5hbWUiOiJ0ZXN0IHVzZXIifQ.fG-K2quUMhFMo3OWXp9whTuxMITbhPF9hyIon5sTo44',
      basePath: 'https://api.impact.dabdevops.de'
    });
    return from(new Promise<VersionedAsset[]>((resolve, reject) => {

      (new AssetsApi(config)).getAssets().then(assets => {

        resolve(assets.data || []);

      }).catch(reject);

    }));

  }
}
