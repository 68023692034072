import { Component } from '@angular/core';

import { SettingsService } from '../../services/settings.service';

@Component({
  templateUrl: './landing.component.html'
})
export class LandingComponent {
  availableLanguages = this.settingsService.availableLanguages;

  constructor(private settingsService: SettingsService) {}
}
