<h2>Assets Dashboard</h2>

<div>

  <!-- (click) passes input value to add() and then clears the input -->
<!--  <button type="button" class="add-button" (click)="add(heroName.value); heroName.value=''">-->
    <button type="button" class="add-button">
    Add asset
  </button>
</div>

<ul class="heroes">
  <li *ngFor="let asset of assets">
    <div *ngFor="let item of asset.history">
      {{item.id}}
      {{item.name}}
      {{item.revenue.value}}
      {{item.revenue.currency}}
    </div>
<!--    <a routerLink="/detail/{{asset}}">-->
<!--      <span class="badge">{{hero.id}}</span> {{hero.name}}-->
<!--    </a>-->
<!--    <button type="button" class="delete" title="delete hero"-->
<!--            (click)="delete(hero)">x</button>-->
  </li>
</ul>
